import React, { useEffect, useState } from 'react'
import { useStyles } from './style'
import MarkdownViewer from '../../components/markdown-viewer'
import README from './readme.md'
import {
  useEvent,
  useEventsOnViewLoad
} from '@emerald-works/react-event-bus-client'

const FormExample = () => {
  const classes = useStyles()
  const [text, setText] = useState('')
  const [usermame, setUsername] = useState('')

  const [devLoggedIn] = useEvent([
    {
      eventName: 'Dev Logged In',
      onSuccess: ({ username }) => {
        setUsername(username)
      }
    }
  ])

  useEventsOnViewLoad(() => {
    devLoggedIn.trigger({
      marcelo: true
    })
  }, [devLoggedIn])

  useEffect(() => {
    fetch(README)
      .then(res => res.text())
      .then(md => setText(md))
      .catch(err => console.error(err))
  }, [])

  return (
    <div className={classes.root}>
      <div>
        <h1>Welcome {usermame}</h1>
        <MarkdownViewer children={text} />
      </div>
    </div>
  )
}

export default FormExample
