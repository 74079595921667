import React from 'react'
import { Routes } from 'react-router-dom'
import { PrivateRoute } from '@emerald-works/react-auth'
import {
  HomePage,
  ChatExample
} from './pages'

const ApplicationRoutes = () => {
  return (
    <Routes>
      <PrivateRoute path='/' element={<HomePage />} />
      <PrivateRoute path='/chat' element={<ChatExample />} />
    </Routes>
  )
}

export default ApplicationRoutes
