import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { AppToolbar } from './components'
import Routes from './routes'
import AppDrawer from './components/app-drawer'
import { useStyles } from './style'
import Logger from './utils/logger'
import RootContextProvider from './contexts/root'

function App () {
  const classes = useStyles()
  return (
    <RootContextProvider>
      <div className={classes.root}>
        {Logger('Conected Successfuly!')}
        <CssBaseline />
        <AppToolbar />
        <AppDrawer />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Routes />
        </main>

      </div>
    </RootContextProvider>
  )
}

export default App
